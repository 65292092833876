import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient } from '@angular/common/http';
import { StoreSetting, StoreSettingUpdate } from '@app/_shared/interfaces/http/store-setting';
import { ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StoreSettingsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/stores/:id:/settings';

  constructor(private _http: HttpClient) {}

  index() {
    return this._http.get(this.endpoint, {
      headers: this.headers,
    });
  }

  patch(id: number, data: StoreSettingUpdate) {
    return this._http.patch<ResponseObject<StoreSetting>>(this.endpoint.replace(':id:', id.toString()), data, {
      headers: this.headers,
    });
  }
}
