<sb-logo-layout-wrapper>
  <div class="sales-platform-container">
    <h1 class="text-center mb-3">Hvilken salgskanal benytter du?</h1>
    <p class="text-center mb-5">Angiv hvilken platform der skal benyttes for forretningen</p>
    <div class="sales-platforms">
      <a [href]="shopifyInstallLink" class="sales-platform">
        <img src="/assets/btn/btn-shopify.svg" alt="Vælg Shopify" title="Vælg Shopify" />
      </a>
      <button (click)="selectPlatform('magento2')" class="sales-platform">
        <img src="/assets/btn/btn-magento2.svg" alt="Vælg Magento 2" title="Vælg Magento 2" />
      </button>
      <button (click)="selectPlatform('smartweb')" class="sales-platform">
        <img src="/assets/btn/btn-smartweb.svg" alt="Vælg SmartWeb" title="Vælg SmartWeb" />
      </button>
      <button (click)="selectPlatform('prestashop')" class="sales-platform">
        <img src="/assets/btn/btn-prestashop.svg" alt="Vælg PrestaShop" title="Vælg PrestaShop" />
      </button>
      <button (click)="selectPlatform('dandomain')" class="sales-platform">
        <img src="/assets/btn/btn-dandomain-classic.svg" alt="Vælg Dandomain classic" title="Vælg Dandomain classic"/>
      </button>
      <button (click)="selectPlatform('woocommerce')" class="sales-platform">
        <img src="/assets/btn/btn-woocommerce.svg" alt="Vælg WooCommerce" title="Vælg WooCommerce" />
      </button>
      <button (click)="selectPlatform('scannet')" class="sales-platform">
        <img src="/assets/btn/btn-scannet.svg" alt="Vælg ScanNet" title="Vælg ScanNet"/>
      </button>
      <button (click)="selectPlatform('magento')" class="sales-platform">
        <img src="/assets/btn/btn-magento.svg" alt="Vælg Magento" title="Vælg Magento"/>
      </button>
      <button (click)="selectPlatform('wannafind')" class="sales-platform">
        <img src="/assets/btn/btn-wannafind.svg" alt="Vælg Wannafind" title="Vælg Wannafind"/>
      </button>
      <button (click)="selectPlatform('dandomain_2')" class="sales-platform">
        <img src="/assets/btn/btn-dandomain.svg" alt="Vælg Dandomain" title="Vælg Dandomain"/>
      </button>
    </div>
  </div>
</sb-logo-layout-wrapper>
