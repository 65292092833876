import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OpenSnackbar, LayoutActionTypes } from './layout.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {
  constructor(private snackBar: MatSnackBar, private _actions$: Actions) {}

  openSnackbar = createEffect(
    () =>
      this._actions$.pipe(
        ofType<OpenSnackbar>(LayoutActionTypes.OpenSnackbar),
        tap((action) =>
          this.snackBar.open(action.title, action.action, { ...action.opts, panelClass: 'global-error-snackbar' })
        )
      ),
    { dispatch: false }
  );
}
