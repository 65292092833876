import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FirstUpperCasePipe } from '@app/_shared/pipes/first-upper-case.pipe';
import { DateWrapperPipe } from './date-wrapper.pipe';
import { GetAccountNumberFromLinePipe } from './get-account-number-from-line.pipe';
import { GetQueryParamFilterDatePipe } from './get-filter-date.pipe';
import { PlatformToDisplayTextPipe } from './platform-to-display-text.pipe';
import { StoreNameByIdPipe } from './store-name-by-id.pipe';
import { OrderStatusTitlePipe } from '@app/_shared/pipes/order-status-title.pipe';
import { BelongsToTitlePipe } from './corrections/belongs-to-title.pipe';
import { SortByPipe } from '@app/_shared/support/array/sort-by.pipe';
import { MiddleEllipsisPipe } from './middle-ellipsis.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FirstUpperCasePipe,
    DateWrapperPipe,
    PlatformToDisplayTextPipe,
    StoreNameByIdPipe,
    GetAccountNumberFromLinePipe,
    GetQueryParamFilterDatePipe,
    OrderStatusTitlePipe,
    BelongsToTitlePipe,
    SortByPipe,
    MiddleEllipsisPipe,
  ],
  exports: [
    FirstUpperCasePipe,
    DateWrapperPipe,
    PlatformToDisplayTextPipe,
    StoreNameByIdPipe,
    GetAccountNumberFromLinePipe,
    GetQueryParamFilterDatePipe,
    OrderStatusTitlePipe,
    BelongsToTitlePipe,
    SortByPipe,
    MiddleEllipsisPipe,
  ],
})
export class CustomPipeModule {}
