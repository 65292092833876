import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BookkeepingStateBadgeComponent } from '@app/_shared/bookkeeping-state-badge/bookkeeping-state-badge.component';
import { ShowBlockedPipe } from './show-blocked.pipe';
import { ShowHasBookingInfoPipe } from './show-has-booking-info.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BadgeModule } from '../components/badge/badge.module';
import { FontAwesomeImportModule } from '../material/font-awesome-import.module';

@NgModule({
  declarations: [BookkeepingStateBadgeComponent, ShowHasBookingInfoPipe, ShowBlockedPipe],
  imports: [CommonModule, MatTooltipModule, BadgeModule, FontAwesomeImportModule],
  exports: [BookkeepingStateBadgeComponent],
})
export class BookkeepingStateBadgeModule {}
