import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { BillingDetail, BillingDetailUpdate, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BillingDetailsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/companies/billing-details';

  constructor(private _http: HttpClient) {}

  index() {
    return this._http.get<ResponseObject<BillingDetail>>(this.endpoint, { headers: this.headers });
  }

  patch(data: BillingDetailUpdate) {
    return this._http.patch<ResponseObject<BillingDetail>>(this.endpoint, data, { headers: this.headers });
  }
}
