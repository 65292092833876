import { createAction, props } from '@ngrx/store';
import { InstallQueryParams } from '../oauth/install/install.resolver';
import { OAuthActions, OnboardingStore } from '../oauth/install/install.service';
import { IntegrationCategory, IntegrationPlatform } from './integrations.service';
import { Alert } from './onboarding.reducer';
import { DataObject, ErrorObject, StoreCreate, Subscription, SubscriptionPlans } from '@app/_shared/interfaces';

export const setIntegrationPlatform = createAction(
  '[Onboarding] setIntegrationPlatform',
  props<{ integrationPlatform: IntegrationPlatform }>()
);

export const setIntegrationId = createAction('[Onboarding] setIntegrationId', props<{ integrationId: number }>());

export const setQueryParams = createAction('[Onboarding] Set Query Params', props<InstallQueryParams>());

export const getOAuthState = createAction('[Onboarding] getOAuthState', props<{ stateToken: string }>());
export const getOAuthStateSuccess = createAction('[Onboarding] getOAuthStateSuccess');
export const getOAuthStateFailure = createAction('[Onboarding] getOAuthStateFailure', props<{ error: any }>());
export const setOAuthState = createAction(
  '[Onboarding] setOAuthState',
  props<{
    action: OAuthActions;
    apiIntegrationId: number;
    category: IntegrationCategory;
    platform: IntegrationPlatform;
    storeId: number;
  }>()
);

export const getStoresSuccess = createAction('[Onboarding] getStoresSuccess');
export const getStoresFailed = createAction('[Onboarding] getStoresFailed', props<{ error: any }>());
export const addStores = createAction('[Onboarding] addStores', props<{ stores: OnboardingStore[] }>());
export const resetStores = createAction('[Onboarding] resetStores');

export const createIntegration = createAction('[Onboarding] createIntegration', props<{ storeId: number }>());
export const createIntegrationSuccess = createAction('[Onboarding] createIntegrationSuccess');
export const createIntegrationFailure = createAction('[Onboarding] createIntegrationFailure', props<{ error: any }>());

export const getPlans = createAction('[Onboarding] getPlans');
export const getPlansSuccess = createAction('[Onboarding] getPlansSuccess');
export const getPlansFailure = createAction('[Onboarding] getPlansFailure', props<{ error: any }>());
export const setPlans = createAction('[Onboarding] setPlans', props<{ plans: DataObject<SubscriptionPlans>[] }>());

export const createStore = createAction('[Onboarding] createStore', props<{ store: StoreCreate }>());
export const createStoreSuccess = createAction('[Onboarding] createStoreSuccess', props<{ storeId: number }>());
export const createStoreFailure = createAction('[Onboarding] createStoreFailure', props<{ error: ErrorObject }>());

export const createFlowDone = createAction('[Onboarding] createFlowDone');

export const getSubscriptions = createAction('[Onboarding] getSubscriptions', props<{ storeId: number }>());
export const getSubscriptionsSuccess = createAction('[Onboarding] getSubscriptionsSuccess');
export const getSubscriptionsFailure = createAction('[Onboarding] getSubscriptionsFailure', props<{ error: any }>());
export const setSubscriptions = createAction(
  '[Onboarding] setSubscriptions',
  props<{ data: DataObject<Subscription>[] }>()
);

export const setAlert = createAction('[Onboarding] setAlert', props<Alert>());

export const redirectToAccountingIntegration = createAction(
  '[Onboarding] Redirect To Accounting Integration Page',
  props<{ store_id: number }>()
);

export const redirectToAcquirerIntegration = createAction(
  '[Onboarding] Redirect To Acquirer Integration Page',
  props<{ store_id: number }>()
);
