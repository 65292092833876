import { createAction, props } from '@ngrx/store';
import { ErrorObject } from '@app/_shared/interfaces';

export const loadDashboardStatistics = createAction('[Dashboard] Load Dashboard Statistics');
export const loadInitialDashboardStatistics = createAction('[Dashboard] Load Initial Dashboard Statistics');

export const loadDashboardStatisticsSuccess = createAction(
  '[Dashboard] Load Dashboard Statistics Success',
  props<{ data: { bookkeepingJobsCount: number; ordersCount: number; apiIntegrationsCount: number } }>()
);

export const loadDashboardStatisticsFailure = createAction(
  '[Dashboard] Load Dashboard Statistics Failure',
  props<{ error: ErrorObject }>()
);
