import { Directive, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';

@Directive({ selector: '[sbSlideController]' })
export class SlideControllerDirective implements OnInit, OnDestroy {
  @Input() $container = null;
  @Input() $next = null;
  @Input() $previous = null;

  @Output() atStart$ = new Subject<boolean>();
  @Output() atEnd$ = new Subject<boolean>();

  private _componentDestroy$ = new Subject<void>();

  private readonly SLIDE_DISTANCE = 215;

  ngOnInit() {
    fromEvent(this.$container, 'scroll')
      .pipe(takeUntil(this._componentDestroy$), debounceTime(50))
      .subscribe((e: any) => {
        const { scrollLeft } = e.target as HTMLDivElement;
        const atStart = scrollLeft === 0;
        const atEnd = this.$container.scrollWidth - scrollLeft <= this.$container.offsetWidth;

        this.atStart$.next(atStart);
        this.atEnd$.next(atEnd);
      });

    fromEvent(this.$next, 'click')
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe(() => this.onNextClick());

    fromEvent(this.$previous, 'click')
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe(() => this.onPreviousClick());
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  private onNextClick() {
    this.$container.scrollLeft += this.SLIDE_DISTANCE;
  }

  private onPreviousClick() {
    this.$container.scrollLeft -= this.SLIDE_DISTANCE;
  }
}
