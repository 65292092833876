import { createAction, props } from '@ngrx/store';
import { DataObject, ErrorObject, Subscription, SubscriptionUpdate } from '@app/_shared/interfaces';

export const updateSubscription = createAction(
  '[Subscription] Update Subscription',
  props<{ data: SubscriptionUpdate }>()
);
export const updateSubscriptionSuccess = createAction(
  '[Subscription] Update Subscription Success',
  props<{ response: DataObject<Subscription> }>()
);
export const updateSubscriptionFailed = createAction(
  '[Subscription] Update Subscription Failed',
  props<{ error: ErrorObject }>()
);

export const createSubscription = createAction(
  '[Subscription] Create Subscription',
  props<{ data: SubscriptionUpdate }>()
);
export const createSubscriptionSuccess = createAction(
  '[Subscription] Create Subscription Success',
  props<{ response: DataObject<Subscription> }>()
);
export const createSubscriptionFailed = createAction(
  '[Subscription] Create Subscription Failed',
  props<{ error: ErrorObject }>()
);
