import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList, SetupIntentCreate, SetupIntent, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SetupIntentsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/companies/setup-intents';

  constructor(private _http: HttpClient) {}

  index() {
    return this._http.get<DataList<SetupIntent, any>>(this.endpoint, { headers: this.headers });
  }

  post(data?: SetupIntentCreate) {
    return this._http.post<ResponseObject<SetupIntent>>(this.endpoint, data, { headers: this.headers });
  }

  delete(setupIntentId) {
    return this._http.delete(this.endpoint + '/' + setupIntentId, { headers: this.headers });
  }
}
