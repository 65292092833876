import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList } from '@app/_shared/interfaces';
import { Plan } from '@app/_shared/interfaces/http/plan';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/users/plans';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<Plan, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
