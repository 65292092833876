import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SupportLinksService } from '@app/_shared/service';
import { AuthService } from '@auth0/auth0-angular';
import { Store, select } from '@ngrx/store';
import { Stores } from '@app/_shared/interfaces/http/stores';
import { CompanyBootstrap, DataObject } from '@app/_shared/interfaces';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { getActiveStores, getCompany, getDefaultStore } from '@app/main-store/bootstrap.selector';
import { Router } from '@angular/router';
import * as BootstrapActions from '@app/main-store/bootstrap.actions';

@Component({
  selector: 'sb-toolbar',
  templateUrl: './toolbar.component.html',  
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() activeRouteGroup: 'home' | 'accounting' | 'settings' | 'account' | null = 'accounting';

  private componentDestroyed$: Subject<void> = new Subject();
  public stores$: Observable<DataObject<Stores>[]> = this.rxStore.pipe(
    takeUntil(this.componentDestroyed$),
    select(getActiveStores)
  );
  public defaultStore$: Observable<DataObject<Stores> | null> = this.rxStore.pipe(
    takeUntil(this.componentDestroyed$),
    select(getDefaultStore),
  );
  public companyInfo$: Observable<DataObject<CompanyBootstrap> | null>;
  public defaultStoreId$: Observable<number | null> = this.defaultStore$.pipe(
    map((store) => store?.id ? +store.id : null)
  );

  constructor(
    private _authService: AuthService,
    private supportLinkService: SupportLinksService,
    private router: Router,
    private rxStore: Store<any>
  ) {}

  ngOnInit(): void {
    this.companyInfo$ = this.rxStore.pipe(takeUntil(this.componentDestroyed$), select(getCompany));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getHelpCenterSupportLink(): string {
    return this.supportLinkService.getList().mainLayout.helpCenter;
  }

  logout() {
    this._authService.logout({
      logoutParams: {
        returnTo: `https://storebuddy.dk`,
      },
    });
  }

  onStoreSelectAction(storeId: number | null, route: string = '/') {
    this.rxStore.dispatch(BootstrapActions.setDefaultStoreById({ storeId: storeId ?? null }));
    this.router.navigate([route]);
  }
}
