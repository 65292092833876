<div class="d-flex flex-row alert">
  <fa-icon class="pe-2 text-danger" [icon]="['fas', 'exclamation-circle']" size="2x" [fixedWidth]="true"> </fa-icon>
  <div>
    <h3 class="mat-h3 fw-lighter mb-0 text-danger">{{ header }}</h3>
    <ng-container *ngIf="data && data.errors && data.errors.length > 0">
      <p class="mat-body" *ngFor="let error of data.errors">
        {{ error.message }}
        <span *ngIf="error.support_link">
          <br />Du kan læse mere i vores guide <a [href]="error.support_link" target="_blank">her</a></span
        >
      </p>
    </ng-container>
  </div>
</div>
<div class="d-flex flex-row justify-content-end">
  <button class="mat-button" (click)="matSnackBar.dismiss()">Ok</button>
</div>
