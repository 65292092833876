import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY, of, forkJoin } from 'rxjs';
import * as DashboardActions from './dashboard.actions';
import { BookkeepingJobsService } from '@app/_shared/service/http/statistics/bookkeeping-jobs.service';
import { OrdersService } from '@app/_shared/service/http/statistics/orders.service';
import { CompanyService } from '@app/_shared/service/http/statistics/company.service';
import { Store } from '@ngrx/store';
import { selectLastUpdatedAt } from '@app/dashboard/store/dashboard.selectors';

@Injectable()
export class DashboardEffects {
  loadDashboards$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadDashboardStatistics),
      switchMap(() =>
        forkJoin({
          bookkeepingJobs: this.bookkeepingJobsService.index(),
          ordersCount: this.ordersCountService.index(),
          company: this.companyCounts.index(),
        }).pipe(
          map((data) =>
            DashboardActions.loadDashboardStatisticsSuccess({
              data: {
                bookkeepingJobsCount: data.bookkeepingJobs.data.attributes.bookkeepings_created_today,
                ordersCount: data.ordersCount.data.attributes.orders_created_today,
                apiIntegrationsCount: data.company.data.attributes.api_integrations_count,
              },
            })
          )
        )
      )
    );
  });

  loadInitialDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadInitialDashboardStatistics),
      concatLatestFrom(() => this.rxStore.select(selectLastUpdatedAt)),
      switchMap(([, lastUpdatedAt]) => {
        if (lastUpdatedAt !== undefined) {
          return EMPTY;
        }
        return of(DashboardActions.loadDashboardStatistics());
      })
    );
  });

  constructor(
    private actions$: Actions,
    private rxStore: Store,
    private bookkeepingJobsService: BookkeepingJobsService,
    private ordersCountService: OrdersService,
    private companyCounts: CompanyService
  ) {}
}
