import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Auth0ClientService, AuthClientConfig, AuthHttpInterceptor, AuthService, AuthState } from '@auth0/auth0-angular';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor extends AuthHttpInterceptor{
  constructor(configFactory: AuthClientConfig, @Inject(Auth0ClientService) auth0Client: Auth0Client, authState: AuthState, private authServiceInterceptor: AuthService) {
    super(configFactory, auth0Client, authState, authServiceInterceptor);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return super.intercept(req, next).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error === 'login_required') {
          this.authServiceInterceptor.loginWithPopup().subscribe({
            next: () => {
              location.reload();
            },
            error: (err) => {
              if (err.error === 'cancelled') {
                this.authServiceInterceptor.logout();
              }
            }
          });
        }
        
        return throwError(() => error);
      })
    );
  }
}