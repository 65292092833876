import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, LengthPagination, ResponseObject, SourceBalanceReportsUpdate } from '@app/_shared/interfaces';
import { SourceBalanceReports } from '@app/_shared/interfaces/http/source-balance-reports';

@Injectable({
  providedIn: 'root',
})
export class SourceBalanceReportsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/source-balance-reports';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<SourceBalanceReports, LengthPagination>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  patch(data: SourceBalanceReportsUpdate) {
    return this._http.patch<ResponseObject<SourceBalanceReports>>(this.endpoint, data, {
      headers: this.headers,
    });
  }
}
