import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from '@app/_shared/components/sidenav/sidenav.component';
import { SbAuthService } from '@app/_shared/service/sb-auth.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const accountingSideNavItems: MenuItem[] = [
  {
    icon: 'basket-shopping',
    title: 'Salg',
    route: '/ordrer',
  },
  {
    icon: 'money-bill-transfer',
    title: 'Betalinger',
    route: '/betalinger/salgskanal',
  },
  {
    icon: 'money-bill-wave',
    title: 'Tilbagebetalinger',
    route: '/tilbage-betalinger/salgskanal',
  },
  {
    icon: 'money-check-pen',
    title: 'Korrektioner',
    route: '/korrektioner',
  },
  {
    icon: 'file-export',
    title: 'Samleposteringer',
    route: '/bundles',
  },
  {
    icon: 'scale-unbalanced',
    title: 'Balance',
    route: '/balances/orders-postings',
    dividerTop: true,
  },
  {
    icon: 'piggy-bank',
    title: 'Udbetalinger',
    route: '/bank',
  },
];

export const orderTabGroupItems = [
  { label: 'Ordrer', route: '/ordrer' },
  { label: 'Refunderinger', route: '/refunderinger' },
  { label: 'Fulfillments', route: '/fulfillments' },
];

export const paymentsTabGroupItems = [
  { label: 'Salgskanal', route: '/betalinger/salgskanal' },
  { label: 'Betalingsgateway', route: '/betalinger/gateway' },
  { label: 'Gavekort', route: '/betalinger/gavekort' },
];

export const repaymentsTabGroupItems = [
  { label: 'Salgskanal', route: '/tilbage-betalinger/salgskanal' },
  { label: 'Betalingsgateway', route: '/tilbage-betalinger/gateway' },
  { label: 'Gavekort', route: '/tilbage-betalinger/gavekort' },
];

export const balanceTabGroupItems = [
  { label: 'Posteringsbalance', route: '/balances/orders-postings' },
  { label: 'Kildebalance', route: '/balances/orders-sources' },
];

@Component({
  selector: 'sb-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  get hostClasses() {
    let classes = 'tw-grid tw-grid-rows-[auto_1fr]';
    return classes;
  }

  public tabGroupItems = [];
  public sideNavItems = [];
  private componentDestroyed$: Subject<void> = new Subject();

  public activeRouteGroup: 'home' | 'accounting' | 'settings' | 'account' | null = 'accounting';
  private _activeRouteGroups = new Map([
    [
      'accounting',
      [
        'ordrer',
        'betalinger',
        'refunderinger',
        'tilbage-betalinger',
        'bank',
        'korrektioner',
        'balances',
        'bundles',
        'fulfillments',
      ],
    ],
    ['settings', ['integrations']],
    ['account', ['konto']],
    ['home', ['']],
  ]);

  public activeAccountingGroup:
    | 'order-view'
    | 'orders'
    | 'payments'
    | 'repayments'
    | 'repayments'
    | 'corrections'
    | 'bundles'
    | 'balance'
    | 'payouts'
    | null = 'orders';
  private _activeAccountingGroups = new Map([
    ['order-view', ['/ordrer/']],
    ['orders', ['/ordrer', '/refunderinger', '/fulfillments']],
    ['payments', ['/betalinger/salgskanal', '/betalinger/gateway', '/betalinger/gavekort']],
    ['repayments', ['/tilbage-betalinger/salgskanal', '/tilbage-betalinger/gateway', '/tilbage-betalinger/gavekort']],
    ['corrections', ['/korrektioner']],
    ['bundles', ['/bundles']],
    ['balance', ['/balances/orders-sources', '/balances/orders-postings']],
    ['payouts', ['/bank']],
  ]);

  private tabGroupItemsMap = {
    orders: orderTabGroupItems,
    payments: paymentsTabGroupItems,
    repayments: repaymentsTabGroupItems,
    balance: balanceTabGroupItems,
  };

  public activeRoute = '';

  constructor(private router: Router, private userRoleService: SbAuthService) {}

  ngOnInit() {
    this.userRoleService
      .isBetaTester()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isBetaTester) => {
        this.tabGroupItemsMap = {
          ...this.tabGroupItemsMap,
          orders: isBetaTester
            ? orderTabGroupItems
            : orderTabGroupItems.filter((item) => item.label !== 'Fulfillments'),
        };
      });

    this.activeRoute = this.removeQueryParameters(this.router.url);

    this.determineActiveRouteGroup(this.router.url);

    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = this.removeQueryParameters(event.urlAfterRedirects);
        this.determineActiveRouteGroup(event.urlAfterRedirects);
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public setActiveRoute(route: string) {
    this.activeRoute = route;
  }

  public isActiveRoute(route: string) {
    return this.activeRoute === route;
  }

  private determineActiveRouteGroup(url: string) {
    this.activeRouteGroup = Array.from(this._activeRouteGroups.keys()).filter((_key) =>
      this._activeRouteGroups.get(_key).some((_path) => url.includes(_path))
    )[0] as 'home' | 'accounting' | 'settings' | 'account';

    if (this.activeRouteGroup === 'accounting') {
      this.determineActiveAccountingGroup(url);
      this.sideNavItems = accountingSideNavItems;
    } else {
      this.sideNavItems = [];
    }
  }

  private determineActiveAccountingGroup(url: string) {
    this.activeAccountingGroup = Array.from(this._activeAccountingGroups.keys()).filter((_key) =>
      this._activeAccountingGroups.get(_key).some((_path) => url.includes(_path))
    )[0] as 'order-view' | 'orders' | 'payments' | 'repayments' | 'corrections' | 'bundles' | 'balance' | 'payouts';

    this.tabGroupItems = this.tabGroupItemsMap[this.activeAccountingGroup] || [];

    this.updateActiveNavItem();
  }

  private updateActiveNavItem() {
    accountingSideNavItems.forEach((item) => {
      item.active = this._activeAccountingGroups
        .get(this.activeAccountingGroup)
        .some((route) => item.route.includes(route));
    });
  }

  private removeQueryParameters(url: string): string {
    return url.split('?')[0];
  }
}
