<form [formGroup]="storeFormGroup">
  <div class="d-flex justify-content-between mb-3">
    <h2 class="m-0">Vælg abonnement</h2>
    <div class="d-flex align-items-center">
      <span class="d-block me-2">Månedligt</span>
      <mat-slide-toggle (change)="onIsYearlyChange($event)" color="primary">Årligt</mat-slide-toggle>
    </div>
  </div>

  <sb-plans
    [subscriptionInterval]="subscriptionInterval"
    [plans]="plans$ | async"
    [selectedPlanId]="storeFormGroup.controls.subscription_plan_id.value"
    (selectPlan$)="onSelectPlan($event)"
    [loading]="loading$ | async"
  ></sb-plans>

  <h2 class="mt-5">Angiv forretningsoplysninger</h2>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Forretningsnavn</mat-label>
    <input formControlName="name" matInput/>
    <mat-error>Skal udfyldes</mat-error>
  </mat-form-field>

  <mat-form-field (click)="datePicker.open()" appearance="outline" class="w-100 mt-2">
    <mat-label>Vælg startdato for bogføring</mat-label>
    <input formControlName="auto_bookkeeping_after" matInput [matDatepicker]="datePicker" [disabled]="true"/>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-hint>DD MMM. ÅÅÅÅ</mat-hint>
    <mat-datepicker #datePicker [disabled]="false"></mat-datepicker>
    <mat-error>Skal udfyldes</mat-error>
  </mat-form-field>

  <div class="terms">
    <mat-checkbox color="primary" formControlName="is_terms_approved">
      Godkend
    </mat-checkbox>

    <a sbLink="https://storebuddy.dk/betingelser">handelsbetingelser</a>
  </div>

  <button
    mat-flat-button
    (click)="onCreateStore()"
    [disabled]="(loading$ | async) || storeFormGroup.invalid"
    [class.loading]="loading$ | async"
    color="primary"
    class="w-100 mt-3"
  >
    Start 10 dages prøveperiode
  </button>
</form>

<div *ngIf="(alert$ | async)?.message !== null" class="alert alert-warning d-flex mt-4" role="alert">
  <fa-icon [icon]="['far', 'exclamation-circle']" [size]="'lg'"></fa-icon>
  <div class="d-flex ms-3 w-100">
    <p class="alert-message mb-0">{{ (alert$ | async).message }}</p>
    <a *ngIf="(alert$ | async).href !== null" mat-button [href]="(alert$ | async).href" class="alert-button ms-auto">
      {{ (alert$ | async).hrefLabel }}
    </a>
  </div>
</div>
