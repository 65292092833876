<mat-sidenav-container class="app-sidenav-container" [hasBackdrop]="sideNavBackdrop">
  <mat-sidenav
    [mode]="'over'"
    (closedStart)="onClose()"
    [position]="'end'"
    #sideNav
    [style.width]="sideNavWidth"
    class="bg-light"
  >
    <div *ngIf="sideNavPortal">
      <ng-template [cdkPortalOutlet]="sideNavPortal"></ng-template>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
