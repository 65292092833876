import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList, PaymentMethod, PaymentMethodCreate, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/companies/payment-methods';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<PaymentMethod, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  post(data: PaymentMethodCreate) {
    return this._http.post<ResponseObject<PaymentMethod>>(this.endpoint, data, { headers: this.headers });
  }

  delete(paymentMethodId: string) {
    return this._http.delete(this.endpoint + '/' + paymentMethodId, { headers: this.headers });
  }
}
