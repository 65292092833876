import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, Payout, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PayoutsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payouts';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<Payout, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  show(id: number, params?: HttpParams) {
    return this._http.get<ResponseObject<Payout>>(this.endpoint + '/' + id, {
      headers: this.headers,
      params,
    });
  }
}
