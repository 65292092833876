import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'sb-auth',
  template: `
    <div id="pre-loader-container">
      <div class="pre-loader-box">
        <div class="lds-dual-ring"></div>
      </div>
      <div class="pre-loader-box">
        <h2 class="pre-loader-text">Loader...</h2>
      </div>
    </div>
  `,
})
export class AuthComponent implements OnInit {
  constructor(private _authService: AuthService, private _window: Window) {}

  ngOnInit() {
    if ((this._window as any)?.dataLayer) {
      (this._window as any)?.dataLayer.push({ event: 'login' });
    }

    this._authService.handleRedirectCallback();
  }
}
