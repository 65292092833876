import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from '@app/_shared/snack-bars/snack-bar-data';

@Component({
  selector: 'sb-snack-bar-success',
  templateUrl: './snack-bar-success.component.html',
  styleUrls: ['./snack-bar.scss'],
})
export class SnackBarSuccessComponent implements OnInit {
  header = 'Success';

  body = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  ngOnInit() {
    if (this.data.header && this.data.header.length > 0) {
      this.header = this.data.header;
    }

    if (this.data.body.length > 0) {
      this.body = this.data.body;
    }
  }
}
