import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CursorPagination, DataList, GatewayTransaction } from '@app/_shared/interfaces';
import { V2023_11 } from '@app/_shared/service/http/versions/2023-11';

@Injectable({
  providedIn: 'root',
})
export class GatewayTransactionsService {
  private headers = new HttpLaravelHeaders().append('X-Storebuddy-Version', V2023_11);

  private endpoint = '/api/v1/user/gateway-operations';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<GatewayTransaction, CursorPagination>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
