<fa-icon [icon]="['fal', 'check-circle']" size="10x" class="d-flex justify-content-center mb-5 text-primary"></fa-icon>

<ng-container [ngSwitch]="platform">
  <ng-container *ngSwitchCase="'shopify'">
    <p class="fw-bold">
      Integrationen imellem Shopify og Storebuddy er nu
      <ng-container *ngIf="action === 'app-re-install' || action === 're-install'">gen</ng-container>oprettet
    </p>
    <ul>
      <li>Vi har oprettet webhooks i Shopify så vi begynder at synkronisere dine ordre med Storebuddy</li>
      <li>
        Benytter du Shopify payments kan du som et tilvalg synkronisere dine udbetalinger med Storebuddy og få disse
        bogført
      </li>
      <li>
        Du kan nu oprette en integration til dit regnskabsprogram og starte med at bogføre dine salg og udbetalinger.
        Herefter kan du følge bogføringen af ordre og udbetalinger i Storebuddy app'en.
      </li>
      <li>Ved at kontakte vores support kan vi hjælpe med import af historik</li>
    </ul>
  </ng-container>

  <ng-container *ngSwitchCase="'dinero'">
    <p class="fw-bold">
      Integrationen imellem Visma Dinero og Storebuddy er nu
      <ng-container *ngIf="action === 'app-re-install' || action === 're-install'">gen</ng-container>oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="'onpay'">
    <p class="fw-bold">
      Integrationen imellem Onpay og Storebuddy er nu
      <ng-container *ngIf="action === 'app-re-install' || action === 're-install'">gen</ng-container>oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler eller tilføje flere integrationer, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="'economic'">
    <p class="fw-bold">
      Integrationen imellem Visma Economic og Storebuddy er nu
      <ng-container *ngIf="action === 'app-re-install' || action === 're-install'">gen</ng-container>oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="'stripe'">
    <p class="fw-bold">
      Integrationen imellem Stripe og Storebuddy er nu
      <ng-container *ngIf="action === 'app-re-install' || action === 're-install'">gen</ng-container>oprettet
    </p>
    <p>
      Hvis du er klar til at opsætte dine bogføringsregler eller tilføje flere integrationer, kan du med fordel gå direkte til integrationer-siden og
      komme videre med det samme.
    </p>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <p class="fw-bold">Din forretning er nu oprettet, og du er klar til at komme videre.</p>
    <p>
      Hvis du er klar til at opsætte dine integrationer, kan du med fordel gå direkte til integrationer-siden og komme
      videre med det samme.
    </p>
  </ng-container>
</ng-container>

<div *ngIf="(subscriptions | sbGetSubscriptionsConfirmationUrl) === null" class="d-flex justify-content-end mt-5">
  <a mat-button [routerLink]="['/', 'integrations', storeId]">Gå til integrationer</a>
  <a mat-flat-button color="primary" [routerLink]="['/']" class="ms-2">Gå til dashboard</a>
</div>

<div *ngIf="(subscriptions | sbGetSubscriptionsConfirmationUrl) !== null" class="d-flex justify-content-end mt-5">
  <a mat-flat-button color="primary" [href]="subscriptions | sbGetSubscriptionsConfirmationUrl">
    Godkend abonnement hos Shopify
  </a>
</div>
