import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Errors } from '@app/_interface/error';

@Component({
  selector: 'sb-snack-bar-errors',
  templateUrl: './snack-bar-errors.component.html',
})
export class SnackBarErrorsComponent {
  header = 'Info';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Errors, public matSnackBar: MatSnackBar) {}
}
