import { State } from '../bootstrap.reducer';
import { ActionCreator, ReducerTypes, on } from '@ngrx/store';
import * as subscriptionsActions from './subscription.actions';
import { RequestState } from '@app/_shared/interfaces';
import { replaceById } from '@app/_shared/support';

export function getSubscriptionOns(): ReducerTypes<State, ActionCreator[]>[] {
  return [
    on(subscriptionsActions.updateSubscription, subscriptionsActions.createSubscription, (state) => ({
      ...state,
      requestState: RequestState.SAVING,
    })),
    on(
      subscriptionsActions.updateSubscriptionSuccess,
      subscriptionsActions.createSubscriptionSuccess,
      (state, action) => {
        let updatedStore = state.company.attributes.stores.find(
          (store) => store.id === action.response.attributes.store_id
        );
        updatedStore = { ...updatedStore, attributes: { ...updatedStore.attributes, subscriptions: action.response } };

        return {
          ...state,
          company: {
            ...state.company,
            attributes: {
              ...state.company.attributes,
              stores: replaceById(state.company.attributes.stores, updatedStore.id, updatedStore),
            },
          },
          requestState: RequestState.SAVED,
        };
      }
    ),
    on(
      subscriptionsActions.updateSubscriptionFailed,
      subscriptionsActions.createSubscriptionFailed,
      (state, action) => ({
        ...state,
        requestState: action.error,
      })
    ),
  ];
}
