<div class="install-container">
  <mat-card *ngIf="(loading$ | async) === false && createFlowDone === false">
    <ng-container [ngSwitch]="(oAuthState$ | async)?.platform">
      <sb-install-dinero *ngSwitchCase="'dinero'"></sb-install-dinero>
      <sb-install-onpay *ngSwitchCase="'onpay'"></sb-install-onpay>
      <sb-install-economic *ngSwitchCase="'economic'"></sb-install-economic>
      <sb-stripe-connect *ngSwitchCase="'stripe'"></sb-stripe-connect>
      <sb-zenegy *ngSwitchCase="'zenegy'"></sb-zenegy>
    </ng-container>
    <mat-card-footer *ngIf="installingIntegration">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
  <mat-card *ngIf="(loading$ | async) === false && createFlowDone === true">
    <mat-card-content>
      <sb-onboarding-success></sb-onboarding-success>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="(loading$ | async) === true && createFlowDone === false">
    <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
      <mat-spinner [diameter]="50" class="mt-5"></mat-spinner>
      <p class="mt-3 mb-5 fw-bold text-black-50">Giv os et øjeblik..</p>
    </mat-card-content>
  </mat-card>
</div>
