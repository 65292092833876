import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, PayoutCharge, PayoutChargeUpdate, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PayoutChargesService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payout-charges';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PayoutCharge, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  patch(id: number, data: PayoutChargeUpdate) {
    return this._http.patch<ResponseObject<PayoutCharge>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }
}
