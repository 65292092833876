import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList, ResponseObject } from '@app/_shared/interfaces/http/data';
import {
  PaymentSettingLookups,
  PaymentSettingLookupsCreate,
} from '@app/_shared/interfaces/http/payment-setting-lookups';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingLookupsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payment-setting-lookups';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PaymentSettingLookups, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  show(id: number, params?: HttpParams) {
    return this._http.get<ResponseObject<PaymentSettingLookups>>(this.endpoint + '/' + id, {
      headers: this.headers,
      params,
    });
  }

  post(data: PaymentSettingLookupsCreate) {
    return this._http.post<ResponseObject<PaymentSettingLookups>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
