import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

const featureTesterKey = 'feature_tester';
const supporterKey = 'supporter';

@Injectable({
  providedIn: 'root',
})
export class SbAuthService extends AuthService {
  isBetaTester(): Observable<boolean> {
    return this.idTokenClaims$.pipe(
      map((claims) => {
        const roles: string[] = claims[environment.auth0Namespace + '/roles'] ?? [];
        return roles.includes(featureTesterKey);
      })
    );
  }

  isSupporter(): Observable<boolean> {
    return this.idTokenClaims$.pipe(
      map((claims) => {
        const roles: string[] = claims[environment.auth0Namespace + '/roles'] ?? [];
        return roles.includes(supporterKey);
      })
    );
  }
}
