import { ActionReducerMap } from '@ngrx/store';
import { MainState } from './main-state';
import { layoutReducer } from './layout.reducer';
import { reducer as bootstrapReducer } from '@app/main-store/bootstrap.reducer';
import { routerReducer } from '@ngrx/router-store';

export const reducers: ActionReducerMap<MainState> = {
  layout: layoutReducer,
  bootstrap: bootstrapReducer,
  router: routerReducer,
};
