import { Directive, HostBinding, Input } from "@angular/core";

@Directive({
    selector: "[sbLink]",
    standalone: true
})
export class LinkDirective {
    @Input("sbLink") url?: string;
    @HostBinding("class") class = "tw-text-blue-600 hover:tw-text-blue-500 tw-transition-colors";
    @HostBinding("target") get target() { return !!this.url ? "_blank" : undefined };
    @HostBinding("href") get href() { return this.url || undefined; };

    constructor() {}
}