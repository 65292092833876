import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList, ResponseObject } from '@app/_shared/interfaces/http/data';
import { PaymentSettings, PaymentSettingsCreate } from '@app/_shared/interfaces/http/payment-settings';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payment-settings';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PaymentSettings, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  show(id: number, params?: HttpParams) {
    return this._http.get<ResponseObject<PaymentSettings>>(this.endpoint + '/' + id, {
      headers: this.headers,
      params,
    });
  }

  post(data: PaymentSettingsCreate) {
    return this._http.post<ResponseObject<PaymentSettings>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(id: number, data: PaymentSettingsCreate) {
    return this._http.patch<ResponseObject<PaymentSettings>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
