import { createAction, props } from '@ngrx/store';
import { DineroOrganization } from '../oauth/install/install.service';
import { UpdateIntegrationPayload } from './integrations.service';

export const getOrganizations = createAction('[Onboarding] getOrganizations', props<{ storeId: number }>());
export const getOrganizationsSuccess = createAction('[Onboarding] getOrganizationsSuccess');
export const getOrganizationsFailure = createAction('[Onboarding] getOrganizationsFailure');
export const setOrganizations = createAction(
  '[Onboarding] setOrganizations',
  props<{ organizations: DineroOrganization[] }>()
);

export const updateDineroIntegration = createAction(
  '[Onboarding] updateDineroIntegration',
  props<UpdateIntegrationPayload<'accounting', 'dinero'>>()
);
export const updateDineroIntegrationSuccess = createAction('[Onboarding] updateDineroIntegrationSuccess');
export const updateDineroIntegrationFailure = createAction('[Onboarding] updateDineroIntegrationFailure');
