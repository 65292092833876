import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sb-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent {
  @Input() heading = '';
  @Input() subHeading = '';

  @Input() showGoBackButton = false;
  @Input() navigateToUrlOnGoBack = ['.'];

  constructor(private _location: Location, private _router: Router) {}

  public onBack() {
    if (this.navigateToUrlOnGoBack[0] !== '.') {
      this._router.navigate(this.navigateToUrlOnGoBack);
    } else {
      this._location.back();
    }
  }
}
