import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataObject, Stores } from '@app/_shared/interfaces';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { getDefaultStore } from '@app/main-store/bootstrap.selector';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, Subject, catchError, filter, finalize, first, fromEvent, map, of, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'sb-search-bar',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  get hostClasses() {
    let classes = 'tw-flex tw-max-w-52 focus-within:tw-max-w-96 tw-transition-max-width tw-duration-200 tw-ease-in-out tw-w-full tw-relative';
    return classes;
  }

  private componentDestroyed$: Subject<void> = new Subject();
  
  public activeStoreId: string | number = null;

  public searchControl = new UntypedFormControl();
  public searchResults: any[] = [];
  public searchOpen = false;
  public searching = false;
  public searchFailed = false;
  public searchNoResults = false;
  public showEnterIndicator = false;

  public defaultStore$: Observable<DataObject<Stores> | null> = EMPTY;

  constructor(
    private router: Router,
    private rxStore: Store<any>,
    private _dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.defaultStore$ = this.rxStore.pipe(
      takeUntil(this.componentDestroyed$),
      select(getDefaultStore),
      tap((store) => {
        const storeId = store?.id;
        this.activeStoreId = storeId;
  
        storeId ? this.searchControl.enable() : this.searchControl.disable();
      })
    );

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((term) => typeof term === 'string'),
        tap((term) => {
          this.searchResults = [];          
          this.searchOpen = false;
          this.showEnterIndicator = term.length > 0;
          if (term.length === 0) {
            this.searchNoResults = false;
          }
        }),
        switchMap((term) =>
          fromEvent(document, 'keyup').pipe(
            filter((e: KeyboardEvent) => e.key.toLowerCase() === 'enter'),
            map(() => term),
            first()
          )
        ),
        tap(() => (this.searching = true)),
        map((term) => term.trim()),
        switchMap((term) => {
          this.showEnterIndicator = false;

          if (!term) {
            this.searching = this.searchNoResults = this.searchFailed = false;
            return of([]);
          }
            
          return this._dashboardService.searchOrderByWebNo(this.activeStoreId, 'web_no', term).pipe(
            tap(() => (this.searchFailed = false)),
            map(({ data }: any) => {
              this.searchNoResults = data.length === 0;
              return data;
            }),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          );
        }),
        tap(() => (this.searching = false)),
        finalize(() => (this.searching = false))
      )
      .subscribe((results: any[]) => {
        this.searchResults = results;
      });
  }
  
  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  onOptionSelected(orderId: string) {
    this.router.navigate(['/', 'ordrer', orderId]);
    (document.activeElement as any).blur();
  }

  displayWith(order) {
    return order ? order.web_no : '';
  }
}
