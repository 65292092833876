import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient } from '@angular/common/http';
import { DataList, ResponseObject } from '@app/_shared/interfaces/http/data';
import { StoreCreate, Stores, StoresUpdate } from '@app/_shared/interfaces/http/stores';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/stores';

  constructor(private _http: HttpClient) {}

  index() {
    return this._http.get<DataList<Stores, any>>(this.endpoint, {
      headers: this.headers,
    });
  }

  post(data: StoreCreate) {
    return this._http.post<ResponseObject<Stores>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(id: number, data: StoresUpdate) {
    return this._http.patch<ResponseObject<Stores>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }

  delete(id) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
