<div class="tw-flex tw-flex-col tw-w-full tw-gap-4">
  <ng-container *ngFor="let menuItem of menuItems">
    <mat-divider *ngIf="menuItem.dividerTop" class="!border-gray-100"></mat-divider>

    <a
      [routerLink]="[menuItem.route]"
      mat-button
      [class.active]="menuItem.active"
      class="tw-group !tw-justify-start !tw-min-w-fit tw-text-nowrap tw-grid grid-cols-[auto_1fr] mat-primary"
    >
      <fa-icon
        [icon]="['fal', menuItem.icon]"
        class="tw-text-gray-500 group-[.active]:tw-text-blue-500"
        size="lg"
        [fixedWidth]="true"
      ></fa-icon>
      <span [ngClass]="{
        'tw-min-w-fit tw-opacity-0 group-hover/menu:tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-in-out': animate
      }" class="tw-ml-1 tw-text-gray-500 group-[.active]:tw-text-gray-800"> {{ menuItem.title }} </span>
    </a>
  </ng-container>


  <mat-divider class="!border-gray-100"></mat-divider>

  <div
    matTooltip="Vælg en forretning for at aktivere link"
    [matTooltipDisabled]="!!(defaultStore$ | async)"
  >
    <a
        [routerLink]="['/integrations/' + +activeStoreId]"
        mat-button
        [disabled]="(defaultStore$ | async) === undefined"
        class="tw-group tw-w-full !tw-justify-start !tw-min-w-fit tw-text-nowrap tw-grid grid-cols-[auto_1fr] mat-primary"
      >
      <fa-icon
        [icon]="['fal', 'gear-complex']"
        [ngClass]="{
          'tw-text-gray-500': (defaultStore$ | async),
          'group-[.active]:tw-text-blue-500': (defaultStore$ | async)
        }"
        size="lg"
        [fixedWidth]="true"
      ></fa-icon>
      <span
        [ngClass]="{
          'tw-min-w-fit tw-opacity-0 group-hover/menu:tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-in-out': animate,
          'tw-ml-1 tw-text-gray-500': (defaultStore$ | async),
          'group-[.active]:tw-text-gray-800': (defaultStore$ | async)
        }"
      > Indstillinger </span>
    </a>
  </div>
</div>
