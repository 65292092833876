import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDashboard from './dashboard.reducer';
import { RequestState } from '@app/_shared/interfaces';

export const selectDashboardState = createFeatureSelector<fromDashboard.State>(fromDashboard.dashboardFeatureKey);

export const selectBookkeepingJobsCount = createSelector(selectDashboardState, (state) => state.bookkeepingJobsCount);
export const selectOrdersCount = createSelector(selectDashboardState, (state) => state.ordersImportedCount);
export const selectIntegrationsCount = createSelector(selectDashboardState, (state) => state.integrationsCounts);
export const selectLastUpdatedAt = createSelector(selectDashboardState, (state) => state.lastUpdatedAt);

const requestStates = createSelector(selectDashboardState, (state) => state.requestStates);
export const selectLoadingStatistics = createSelector(
  requestStates,
  (state) => state.statistics === RequestState.LOADING
);
