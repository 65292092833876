import { Injectable } from '@angular/core';
import { CurrencyCode } from '@app/_shared/interfaces/currency-code';

@Injectable({
  providedIn: 'root',
})
export class CurrencyCodeService {
  private list: CurrencyCode[] = [
    { name: 'Afghani', id: 'afn' },
    { name: 'Lek', id: 'all' },
    {
      name: 'Algiersk dinar',
      id: 'dzd',
    },
    { name: 'US dollar', id: 'usd' },
    { name: 'Kwanza', id: 'aoa' },
    {
      name: 'Østkaribisk dollar',
      id: 'xcd',
    },
    { name: 'Argentinsk peso', id: 'ars' },
    { name: 'Armenisk dram', id: 'amd' },
    {
      name: 'Arubansk florin',
      id: 'awg',
    },
    { name: 'Australsk dollar', id: 'aud' },
    { name: 'Euro', id: 'eur' },
    {
      name: 'Aserbajdsjansk manat',
      id: 'azn',
    },
    { name: 'Bahamansk dollar', id: 'bsd' },
    { name: 'Bahrainsk dinar', id: 'bhd' },
    {
      name: 'Taka',
      id: 'bdt',
    },
    { name: 'Barbadisk dollar', id: 'bbd' },
    { name: 'Hviderussisk rubel', id: 'byr' },
    {
      name: 'Belize Dollar',
      id: 'bzd',
    },
    { name: 'CFA Franc BCEAO', id: 'xof' },
    { name: 'Bermuda dollar', id: 'bmd' },
    {
      name: 'Ngultrum',
      id: 'btn',
    },
    { name: 'Indisk rupee', id: 'inr' },
    { name: 'Boliviano', id: 'bob' },
    {
      name: 'Boliviano',
      id: 'bov',
    },
    { name: 'Konvertibilna mark', id: 'bam' },
    { name: 'Pula', id: 'bwp' },
    {
      name: 'Norsk krone',
      id: 'nok',
    },
    { name: 'Brasiliansk real', id: 'brl' },
    { name: 'Bruneisk dollar', id: 'bnd' },
    {
      name: 'Bulgarsk lev',
      id: 'bgn',
    },
    { name: 'Burundisk franc', id: 'bif' },
    { name: 'Escudo', id: 'cve' },
    {
      name: 'Riel',
      id: 'khr',
    },
    { name: 'CFA Franc BEAC', id: 'xaf' },
    { name: 'Canadisk dollar', id: 'cad' },
    {
      name: 'Caymanøernes dollar',
      id: 'kyd',
    },
    { name: 'Unidad de Fomento', id: 'clf' },
    { name: 'Chilensk peso', id: 'clp' },
    {
      name: 'Yuan renminbi',
      id: 'cny',
    },
    { name: 'Colombiansk peso', id: 'cop' },
    { name: 'Unidad de Valor Real', id: 'cou' },
    {
      name: 'Comorisk franc',
      id: 'kmf',
    },
    { name: 'Congolesisk franc', id: 'cdf' },
    { name: 'Newzealandsk dollar', id: 'nzd' },
    {
      name: 'Colon',
      id: 'crc',
    },
    { name: 'Kuna', id: 'hrk' },
    { name: 'Peso convertible', id: 'cuc' },
    {
      name: 'Cubansk peso',
      id: 'cup',
    },
    { name: 'Antillansk gylden', id: 'ang' },
    { name: 'Tjekkisk koruna', id: 'czk' },
    {
      name: 'Dansk krone',
      id: 'dkk',
    },
    { name: 'Djiboutisk franc', id: 'djf' },
    { name: 'Dominikansk peso', id: 'dop' },
    {
      name: 'Egyptisk pund',
      id: 'egp',
    },
    { name: 'Salvadoransk colon', id: 'svc' },
    { name: 'Nakfa', id: 'ern' },
    {
      name: 'Etiopisk birr',
      id: 'etb',
    },
    { name: 'Falklandsøernes pund', id: 'fkp' },
    { name: 'Fijiansk dollar', id: 'fjd' },
    {
      name: 'CFP Franc',
      id: 'xpf',
    },
    { name: 'Dalasi', id: 'gmd' },
    { name: 'Lari', id: 'gel' },
    { name: 'Cedi', id: 'ghs' },
    {
      name: 'Gibraltar pund',
      id: 'gip',
    },
    { name: 'Quetzal', id: 'gtq' },
    { name: 'Pund sterling', id: 'gbp' },
    {
      name: 'Guineansk franc',
      id: 'gnf',
    },
    { name: 'Guyansk dollar', id: 'gyd' },
    { name: 'Gourde', id: 'htg' },
    {
      name: 'Lempira',
      id: 'hnl',
    },
    { name: 'Hong Kong dollar', id: 'hkd' },
    { name: 'Forint', id: 'huf' },
    {
      name: 'Islandsk krone',
      id: 'isk',
    },
    { name: 'Rupiah', id: 'idr' },
    { name: 'SDR (Special Drawing Right)', id: 'xdr' },
    {
      name: 'Iransk rial',
      id: 'irr',
    },
    { name: 'Irakisk dinar', id: 'iqd' },
    { name: 'Ny israelsk shekel', id: 'ils' },
    {
      name: 'Jamaicansk dollar',
      id: 'jmd',
    },
    { name: 'Yen', id: 'jpy' },
    { name: 'Jordansk dinar', id: 'jod' },
    {
      name: 'Tenge',
      id: 'kzt',
    },
    { name: 'Kenyansk shilling', id: 'kes' },
    { name: 'Nordkoreansk won', id: 'kpw' },
    {
      name: 'Won',
      id: 'krw',
    },
    { name: 'Kuwaitisk dinar', id: 'kwd' },
    { name: 'Som', id: 'kgs' },
    {
      name: 'Kip',
      id: 'lak',
    },
    { name: 'Libanesisk pund', id: 'lbp' },
    { name: 'Loti', id: 'lsl' },
    {
      name: 'Rand',
      id: 'zar',
    },
    { name: 'Liberiansk dollar', id: 'lrd' },
    { name: 'Libysk dinar', id: 'lyd' },
    {
      name: 'Schweizerfranc',
      id: 'chf',
    },
    { name: 'Pataca', id: 'mop' },
    { name: 'Denar', id: 'mkd' },
    { name: 'Ariary', id: 'mga' },
    {
      name: 'Kwacha',
      id: 'mwk',
    },
    { name: 'Ringgit', id: 'myr' },
    { name: 'Rufiyaa', id: 'mvr' },
    {
      name: 'Ouguiya',
      id: 'mru',
    },
    { name: 'Mauritisk rupee', id: 'mur' },
    { name: 'ADB Unit of Aidount', id: 'xua' },
    {
      name: 'Mexicansk peso',
      id: 'mxn',
    },
    { name: 'Mexican Unidad de Inversion (UDI)', id: 'mxv' },
    { name: 'Moldovisk leu', id: 'mdl' },
    {
      name: 'Tugrik',
      id: 'mnt',
    },
    { name: 'Marokkansk dirham', id: 'mad' },
    { name: 'Metical', id: 'mzn' },
    {
      name: 'Kyat',
      id: 'mmk',
    },
    { name: 'Namibisk dollar', id: 'nad' },
    { name: 'Nepalesisk rupee', id: 'npr' },
    {
      name: 'Cordoba Oro',
      id: 'nio',
    },
    { name: 'Naira', id: 'ngn' },
    { name: 'Omansk rial', id: 'omr' },
    {
      name: 'Pakistansk rupee',
      id: 'pkr',
    },
    { name: 'Balboa', id: 'pab' },
    { name: 'Kina', id: 'pgk' },
    { name: 'Guarani', id: 'pyg' },
    {
      name: 'Nuevo sol',
      id: 'pen',
    },
    { name: 'Filippinsk peso', id: 'php' },
    { name: 'Zloty', id: 'pln' },
    {
      name: 'Riyal',
      id: 'qar',
    },
    { name: 'Ny rumænsk leu', id: 'ron' },
    { name: 'Russisk rubel', id: 'rub' },
    {
      name: 'Rwandisk franc',
      id: 'rwf',
    },
    { name: 'Sankt Helena pund', id: 'shp' },
    { name: 'Tala', id: 'wst' },
    {
      name: 'Dobra',
      id: 'stn',
    },
    { name: 'Saudisk riyal', id: 'sar' },
    { name: 'Serbisk dinar', id: 'rsd' },
    {
      name: 'Seychellisk rupee',
      id: 'scr',
    },
    { name: 'Leone', id: 'sll' },
    { name: 'Singaporeansk dollar', id: 'sgd' },
    {
      name: 'Sucre',
      id: 'xsu',
    },
    { name: 'Salomonøernes dollar', id: 'sbd' },
    { name: 'Somalisk shilling', id: 'sos' },
    {
      name: 'Sydsudansk pund',
      id: 'ssp',
    },
    { name: 'Srilankansk rupee', id: 'lkr' },
    { name: 'Sudansk pund', id: 'sdg' },
    {
      name: 'Surinam dollar',
      id: 'srd',
    },
    { name: 'Lilangeni', id: 'szl' },
    { name: 'Svensk krone', id: 'sek' },
    {
      name: 'WIR Euro',
      id: 'che',
    },
    { name: 'WIR Franc', id: 'chw' },
    { name: 'Syrisk pund', id: 'syp' },
    {
      name: 'Ny taiwansk dollar',
      id: 'twd',
    },
    { name: 'Somoni', id: 'tjs' },
    { name: 'Tanzanisk shilling', id: 'tzs' },
    {
      name: 'Baht',
      id: 'thb',
    },
    { name: 'Pa\u2019anga', id: 'top' },
    { name: 'Trinidad og Tobago dollar', id: 'ttd' },
    {
      name: 'Tunesisk dinar',
      id: 'tnd',
    },
    { name: 'Tyrkisk lira', id: 'try' },
    { name: 'Ny turkmensk manat', id: 'tmt' },
    {
      name: 'Ugandisk shilling',
      id: 'ugx',
    },
    { name: 'Hryvnia', id: 'uah' },
    { name: 'UAE Dirham', id: 'aed' },

    { name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)', id: 'uyi' },
    { name: 'Peso uruguayo', id: 'uyu' },
    {
      name: 'Som',
      id: 'uzs',
    },
    { name: 'Vatu', id: 'vuv' },
    { name: 'Bolivar', id: 'vef' },
    { name: 'Dong', id: 'vnd' },
    {
      name: 'Yemenitisk rial',
      id: 'yer',
    },
    { name: 'Zambisk kwacha', id: 'zmw' },
    { name: 'Zimbabwe dollar', id: 'zwl' },
  ];

  constructor() {}

  getList(): CurrencyCode[] {
    return this.list;
  }
}
