<div class="container h-100">
  <ng-container *ngIf="(defaultStoreSubscription$ | async)?.attributes.status === 'declined'"
                [ngTemplateOutlet]="declinedShopifySubscription"></ng-container>

  <ng-container *ngIf="(defaultStoreSubscription$ | async)?.attributes.status === 'expired'"
                [ngTemplateOutlet]="expiredShopifySubscription"></ng-container>

  <div class="row mb-3">
    <div class="col-12">
      <sb-page-heading heading="Hej Buddy!" subHeading="Få det hurtige overblik dine bogføringsintegrationer">
      </sb-page-heading>
    </div>
  </div>

  <ng-container *ngIf="(hasFailedBookkeepingJob$ | async) === true" [ngTemplateOutlet]="failedBookkeepingJob"></ng-container>

  <div class="card-row">
    <mat-card>
      <mat-card-content>
        <div style="text-align: right">
          <button style="background-color: transparent; border-style: none; padding-right: 0; cursor: pointer" (click)="onLoadStatistics()" [disabled]="loading$ | async">
            <fa-icon [icon]="['fal', 'sync-alt']" [fixedWidth]="true" [animation]="(loading$ | async) ? 'spin': undefined"></fa-icon>
          </button>
        </div>
        <div style="display: flex; flex-direction: row; width: 100%; align-items: center">
          <fa-duotone-icon primaryColor="#305F72"
                           secondaryColor="#568EA6"
                           [icon]="['fad', 'list-check']"
                           size="4x"
                           [fixedWidth]="true">
          </fa-duotone-icon>
          <div style="margin-left: auto; text-align: right">
            <h1 style="font-size: xx-large; margin-bottom: 0; margin-top: 10px">{{ bookkeepingJobsCount$ | async }}</h1>
            <span class="text-muted-light">Igangværende bogføringsjobs</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div style="text-align: right;">
          <button style="background-color: transparent; border-style: none; padding-right: 0; cursor: pointer" (click)="onLoadStatistics()" [disabled]="loading$ | async">
            <fa-icon [icon]="['fal', 'sync-alt']" [fixedWidth]="true" [animation]="(loading$ | async) ? 'spin': undefined"></fa-icon>
          </button>
        </div>
        <div style="display: flex; flex-direction: row; width: 100%; align-items: center">
          <fa-duotone-icon primaryColor="#305F72"
                           secondaryColor="#568EA6"
                           [icon]="['fad', 'cart-shopping']"
                           size="4x"
                           [fixedWidth]="true">
          </fa-duotone-icon>
          <div style="margin-left: auto; text-align: right">
            <h1 style="font-size: xx-large; margin-bottom: 0; margin-top: 10px">{{ ordersCount$ | async }}</h1>
            <span class="text-muted-light">Ordre oprettet i dag</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div style="text-align: right;" [routerLink]="['/stores/sales-platform']">
          <fa-icon [icon]="['fal', 'circle-plus']" [fixedWidth]="true" style="cursor:pointer;"></fa-icon>
        </div>
        <div style="display: flex; flex-direction: row; width: 100%; align-items: center">
          <fa-duotone-icon primaryColor="#305F72"
                           secondaryColor="#568EA6"
                           [icon]="['fad', 'store']"
                           size="4x"
                           [fixedWidth]="true">
          </fa-duotone-icon>
          <div style="margin-left: auto; text-align: right">
            <h1 style="font-size: xx-large; margin-bottom: 0; margin-top: 10px">{{ activeStoresCount$ | async }}</h1>
            <span class="text-muted-light">Forretninger</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div style="display: flex; flex-direction: row; width: 100%; align-items: center; padding-top: 20px">
          <fa-duotone-icon primaryColor="#305F72"
                           secondaryColor="#568EA6"
                           [icon]="['fad', 'chart-network']"
                           size="4x"
                           [fixedWidth]="true">
          </fa-duotone-icon>
          <div style="margin-left: auto; text-align: right">
            <h1 style="font-size: xx-large; margin-bottom: 0; margin-top: 10px">{{integrationsCount$ | async}}</h1>
            <span class="text-muted-light">Integrationer</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="pt-2 w-100" style="text-align: right; font-style: italic">
    <span class="text-muted-light">Sidst opdateret: <ng-container *ngIf="lastUpdatedAt$ !== undefined">
      {{ lastUpdatedAt$ | async }}
    </ng-container></span>
  </div>

</div>

<ng-template #declinedShopifySubscription>
  <div class="row mb-3">
    <div class="col-12">
      <div class="alert alert-warning alert-with-buttons" role="alert">
        <fa-icon class="text-secondary" [icon]="['fal', 'exclamation-circle']" size="2x"></fa-icon>
        <p>
          Abonnementsbetalingen er afvist i Shopify, du skal have et godkendt abonnement for at starte
          bogføringsservicen. Klik på Genaktiver, for at godkende dit nuværende abonnementsplan eller gå til
          abonnementsindstillinger for at vælge et nyt plan.
        </p>
        <ng-container *ngIf="(defaultStore$ | async)?.id as storeId">
          <div class="button-row">
            <button id="reactivate-subscription-button" mat-stroked-button [class.loading]="savingSubscription$ | async"
                    (click)="onReactivateSubscription(+storeId)">Genaktiver
            </button>
            <a mat-stroked-button routerLink="/integrations/{{storeId}}/subscriptions">Gå til abonnement</a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expiredShopifySubscription>
  <div class="row mb-3">
    <div class="col-12">
      <div class="alert alert-warning alert-with-buttons" role="alert">
        <fa-icon class="text-warning" [icon]="['fal', 'exclamation-circle']" size="2x"></fa-icon>
        <p>Fristen for betalingsgodkendelse af Storebuddy abonnementet i Shopify er overskredet, du skal have et
          godkendt abonnement for at starte
          bogføringsservicen. Klik på Genaktiver, for at godkende det nuværende abonnementsplan eller eller gå til
          abonnementsindstillinger for at vælge et nyt plan.
        </p>
        <ng-container *ngIf="(defaultStore$ | async)?.id as storeId">
          <div class="button-row">
            <button mat-stroked-button (click)="onReactivateSubscription(+storeId)"
                    [class.loading]="savingSubscription$ | async">Genaktiver
            </button>
            <a mat-stroked-button routerLink="/integrations/{{storeId}}/subscriptions">Gå til abonnement</a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #failedBookkeepingJob>
  <div class="row mb-3">
    <div class="col-12">
      <div class="alert alert-warning alert-with-buttons align-items-center" role="alert">
        <fa-icon class="text-warning" [icon]="['fal', 'exclamation-circle']" size="2x"></fa-icon>
        <p class="mb-0">Bogføringsprocessen er midlertidigt stoppet, da der er sket en uventet fejl på et igangværende
          bogføringsjob.
          Vi undersøger fejlen og genstarter så hurtigt som muligt.
        </p>
      </div>
    </div>
  </div>
</ng-template>
