import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, PayoutSettingCreate, ResponseObject } from '@app/_shared/interfaces';
import { PayoutSetting } from '@app/_shared/interfaces/http/payout-setting';

@Injectable({
  providedIn: 'root',
})
export class PayoutSettingsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payout-settings';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PayoutSetting, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  post(data: PayoutSettingCreate) {
    return this._http.post<ResponseObject<PayoutSetting>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(id: number, data: PayoutSettingCreate) {
    return this._http.patch<ResponseObject<PayoutSetting>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
