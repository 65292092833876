import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CursorPagination, DataList, ResponseObject } from '@app/_shared/interfaces';
import { Order, OrderUpdate } from '@app/_shared/interfaces/http/order/order';
import { OrderExtended } from '@app/_shared/interfaces/http/order/order-extended';
import { V2023_11 } from '@app/_shared/service/http/versions/2023-11';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private headers: HttpLaravelHeaders = new HttpLaravelHeaders();
  private endpoint = '/api/v1/user/orders';

  constructor(private _http: HttpClient) {}

  show(id: number) {
    return this._http.get<ResponseObject<OrderExtended>>(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }

  index(params: HttpParams) {
    const headers = new HttpLaravelHeaders().append('X-Storebuddy-Version', V2023_11);
    return this._http.get<DataList<Order, CursorPagination>>(this.endpoint, {
      headers,
      params,
    });
  }

  patch(id: number, data: OrderUpdate) {
    return this._http.patch<ResponseObject<Order>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }
}
