import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList } from '@app/_shared/interfaces';
import { Refund } from '@app/_shared/interfaces/http/refund/refund';

@Injectable({
  providedIn: 'root',
})
export class RefundsService {
  private headers = new HttpLaravelHeaders();
  private endpoint = '/api/v1/user/refunds';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<Refund, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
