<div class="tw-text-2xl mb-0 fw-bold">{{ heading }}</div>
<p class="mat-body text-muted mb-2 d-flex">
  <ng-container *ngIf="showGoBackButton">
    <span (click)="onBack()" class="go-back-button text-muted">
      <fa-icon [icon]="['fas', 'chevron-left']" size="xs" class="me-1"></fa-icon>
      Tilbage
    </span>
    <mat-divider [vertical]="true" class="mx-2 my-1"></mat-divider>
  </ng-container>
  {{ subHeading }}
</p>
<mat-divider></mat-divider>
