import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, PayoutSettingLookupCreate, ResponseObject } from '@app/_shared/interfaces';
import { PayoutSettingLookup } from '@app/_shared/interfaces/http/payout-setting-lookup';

@Injectable({
  providedIn: 'root',
})
export class PayoutSettingLookupsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payout-setting-lookups';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PayoutSettingLookup, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  post(data: PayoutSettingLookupCreate) {
    return this._http.post<ResponseObject<PayoutSettingLookup>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
