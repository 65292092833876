import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList } from '@app/_shared/interfaces';
import { RefundTransactionConfigOption } from '@app/_shared/interfaces/http/refund-transaction-config-option';

@Injectable({
  providedIn: 'root',
})
export class RefundTransactionConfigOptionsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/refund-transaction-config-options';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<RefundTransactionConfigOption, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
