import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient } from '@angular/common/http';
import { BookkeepingQueue, BookkeepingQueueCreate } from '@app/_shared/interfaces/http/bookkeeping-queue';
import { DataList } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BookkeepingQueueService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/bookkeeping-queue';

  constructor(private _http: HttpClient) {}

  post(data: BookkeepingQueueCreate) {
    return this._http.post<DataList<BookkeepingQueue, any>>(this.endpoint, data, {
      headers: this.headers,
    });
  }
}
