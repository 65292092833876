import { createFeature, createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { DateTime } from 'luxon';
import { ApiCallState, RequestState } from '@app/_shared/interfaces';

export const dashboardFeatureKey = 'dashboard';

export interface State {
  bookkeepingJobsCount: number;
  ordersImportedCount: number;
  integrationsCounts: number;
  lastUpdatedAt: DateTime;
  requestStates: {
    statistics: ApiCallState;
  };
}

export const initialState: State = {
  bookkeepingJobsCount: 0,
  integrationsCounts: 0,
  ordersImportedCount: 0,
  lastUpdatedAt: undefined,
  requestStates: {
    statistics: RequestState.INIT,
  },
};

export const reducer = createReducer(
  initialState,
  on(
    DashboardActions.loadDashboardStatistics,
    (state): State => ({
      ...state,
      requestStates: {
        statistics: RequestState.LOADING,
      },
    })
  ),
  on(
    DashboardActions.loadDashboardStatisticsSuccess,
    (state, { data }): State => ({
      ...state,
      bookkeepingJobsCount: data.bookkeepingJobsCount,
      integrationsCounts: data.apiIntegrationsCount,
      ordersImportedCount: data.ordersCount,
      lastUpdatedAt: DateTime.now(),
      requestStates: {
        statistics: RequestState.INIT,
      },
    })
  ),
  on(
    DashboardActions.loadDashboardStatisticsFailure,
    (state): State => ({
      ...state,
      requestStates: {
        statistics: RequestState.INIT,
      },
    })
  )
);

export const dashboardFeature = createFeature({
  name: dashboardFeatureKey,
  reducer,
});
