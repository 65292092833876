import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export class HttpLaravelHeaders extends HttpHeaders {
  constructor() {
    super({ 'X-SB-Use-Laravel-API': 'true' });
  }
}

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private useLaravelApiHeaderKey = new HttpLaravelHeaders().keys()[0];
  private readonly basePath: string = '/api/v2/';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.getUrl(req);
    return next.handle(req.clone({ url }));
  }

  private getUrl(req: HttpRequest<any>): string {
    const { apiUrl, apiLaravelUrl } = environment;

    if (req.headers.has(this.useLaravelApiHeaderKey)) {
      return `${apiLaravelUrl}${req.url}`;
    } else {
      return `${apiUrl}${this.basePath}${req.url}`;
    }
  }
}
