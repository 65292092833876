import { ResolveFn, Router } from '@angular/router';
import * as OnboardingActions from '@app/onboarding/store/onboarding.actions';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';

export interface InstallQueryParams {
  code?: string;
  hmac?: string;
  host?: string;
  shop?: string;
  state?: string;
  timestamp?: string;
  key?: string;
  password?: string;
  token?: string;
}

export const installResolver: ResolveFn<boolean> = (route) => {
  const { code, hmac, host, shop, state, timestamp, token } = route.queryParams as InstallQueryParams;

  if (!state) {
    inject(Router).navigate(['/']);
    return false;
  }

  const rxStore = inject(Store);

  rxStore.dispatch(OnboardingActions.resetStores());

  rxStore.dispatch(
    OnboardingActions.setQueryParams({
      state,
      code: code ?? null,
      hmac: hmac ?? null,
      host: host ?? null,
      shop: shop ?? null,
      timestamp: timestamp ?? null,
      token: token ?? null,
    })
  );

  rxStore.dispatch(
    OnboardingActions.getOAuthState({
      stateToken: state,
    })
  );
  return true;
};
