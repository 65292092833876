import { Portal } from '@angular/cdk/portal';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export interface RightSideNavPortalOptions {
  portal: Portal<any>;
  width: string;
  backdrop: boolean;
}

@Injectable({ providedIn: 'root' })
export class RightSideNavService {
  public currentPortal$: BehaviorSubject<RightSideNavPortalOptions> = new BehaviorSubject({
    portal: null,
    width: null,
    backdrop: true,
  });
  public close$: EventEmitter<void> = new EventEmitter();

  open(portal: Portal<any>, width: string = null, backdrop: boolean = true) {
    this.currentPortal$.next({ portal, width, backdrop });
  }

  async close() {
    const { portal, width, backdrop } = await firstValueFrom(this.currentPortal$);

    if (portal && portal.isAttached) {
      portal.detach();

      this.currentPortal$.next({ portal: null, width, backdrop });
      this.close$.next();
    }
  }
}
