import { take } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { getStoreById } from '@app/main-store/bootstrap.selector';

@Pipe({
  name: 'storeNameById',
})
export class StoreNameByIdPipe implements PipeTransform {
  constructor(private _store: Store) {}

  transform(storeId: string | number): any {
    if (!storeId) {
      return '';
    }

    let store = null;

    this._store.pipe(select(getStoreById(+storeId)), take(1)).subscribe((_store) => (store = _store));

    return store?.name ?? '';
  }
}
