import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginRedirectComponent } from '@app/auth/login-redirect.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthComponent } from './auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MainLayoutGuard } from './main-layout/main-layout.guard';
import { routes as onboardingRoutes } from './onboarding/onboarding.routes';
import { SignupRedirectComponent } from '@app/auth/signup-redirect.component';

const routes: Routes = [
  // { path: '', component: MaintenanceComponent, },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [],
  },
  {
    path: 'signup',
    component: SignupRedirectComponent,
    canActivate: [],
  },
  {
    path: 'login',
    component: LoginRedirectComponent,
    canActivate: [],
  },
  {
    path: 'login-redirect',
    component: LoginRedirectComponent,
    canActivate: [],
  },
  {
    matcher: (url) => {
      const firstUrlSegment = url[0];
      const firstUrlSegmentPath = firstUrlSegment?.path ?? '';

      if (onboardingRoutes.some((route) => route.path.split('/')[0] === firstUrlSegmentPath)) {
        return {
          consumed: [],
        };
      }

      return null;
    },
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuard, MainLayoutGuard],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, MainLayoutGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'integrations',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'ordrer',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'orders/accounting',
        loadChildren: () =>
          import('./order-accounting-details/order-accounting-details.module').then(
            (m) => m.OrderAccountingDetailsModule
          ),
      },
      {
        path: 'bank',
        loadChildren: () => import('./payout/payout.module').then((m) => m.PayoutModule),
      },
      {
        path: 'konto',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'betalinger',
        loadChildren: () =>
          import('./incoming-transactions/incoming-transactions.module').then((m) => m.IncomingTransactionsModule),
      },
      {
        path: 'refunderinger',
        loadChildren: () => import('./refunds/refunds.module').then((m) => m.RefundsModule),
      },
      {
        path: 'tilbage-betalinger',
        loadChildren: () =>
          import('./outgoing-transactions/outgoing-transactions.module').then((m) => m.OutgoingTransactionsModule),
      },
      {
        path: 'invoice-settings',
        loadChildren: () => import('./invoice-settings/invoice-settings.module').then((m) => m.InvoiceSettingsModule),
      },
      {
        path: 'balances/orders-postings',
        loadChildren: () =>
          import('./orders-postings-balance/orders-postings.module').then((m) => m.OrdersPostingsBalanceModule),
      },
      {
        path: 'balances/orders-sources',
        loadChildren: () =>
          import('./orders-sources-balance/orders-sources.module').then((m) => m.OrdersSourcesBalanceModule),
      },
      {
        path: 'korrektioner',
        loadChildren: () => import('./corrections/corrections.module').then((m) => m.CorrectionsModule),
      },
      {
        path: 'bundles',
        loadChildren: () => import('./bundle/bundle.module').then((m) => m.BundleModule),
      },
      {
        path: 'fulfillments',
        loadChildren: () => import('./fulfillments/fulfillments.module').then((m) => m.FulfillmentsModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
