import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList } from '@app/_shared/interfaces';
import { OrderStatus } from '@app/_shared/interfaces/http/order-status';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusService {
  private headers = new HttpLaravelHeaders();
  private endpoint = '/api/v1/user/order-statuses';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<DataList<OrderStatus, any>>(this.endpoint, { headers: this.headers, params });
  }
}
