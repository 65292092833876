interface Base {
  id: string | number;
}

export function replaceById<T extends Base>(array: T[], id: string | number, replaceObj: T): T[] {
  const idx = array.findIndex((x) => x.id === id);
  return [...array.slice(0, idx), replaceObj, ...array.slice(idx + 1)];
}

export function removeById<T extends Base>(array: T[], id: string | number): T[] {
  const idx = array.findIndex((x) => x.id === id);
  return [...array.slice(0, idx), ...array.slice(idx + 1)];
}
