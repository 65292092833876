import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeadingComponent } from '@app/_shared/heading/page-heading.component';
import { FontAwesomeImportModule } from '../material/font-awesome-import.module';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [PageHeadingComponent],
  exports: [PageHeadingComponent],
  imports: [CommonModule, RouterModule, FontAwesomeImportModule, MatDividerModule],
})
export class HeadingModule {}
