import { Action } from '@ngrx/store';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export enum LayoutActionTypes {
  OpenSnackbar = '[Layout] open snack bar',
}

/**
 * @deprecated
 */
export class OpenSnackbar implements Action {
  readonly type = LayoutActionTypes.OpenSnackbar;

  constructor(public title: string, public action: string, public opts?: MatSnackBarConfig) {}
}

/**
 * @deprecated
 */
export type LayoutActions = OpenSnackbar;
