import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList, ResponseObject } from '@app/_shared/interfaces/http/data';
import { Subscription, SubscriptionUpdate } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/subscriptions';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    if (params === undefined) {
      params = new HttpParams();
    }

    return this._http.get<DataList<Subscription, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  show(id: number) {
    return this._http.get<ResponseObject<Subscription>>(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }

  post(data: SubscriptionUpdate) {
    return this._http.post<ResponseObject<Subscription>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(data: SubscriptionUpdate) {
    return this._http.patch<ResponseObject<Subscription>>(this.endpoint, data, {
      headers: this.headers,
    });
  }
}
