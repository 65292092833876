import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { selectOnboardingState } from '@app/onboarding/store/onboarding.selector';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, first, map, switchMap, tap } from 'rxjs';
import * as OnboardingActions from '../../../store/onboarding.actions';

@Injectable({ providedIn: 'root' })
export class OnboardingSuccessGuard  {
  constructor(private _store: Store, private _router: Router, private _actions$: Actions) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._store.pipe(
      select(selectOnboardingState),
      first(),
      tap(({ flow: { done } }) => {
        if (!done) {
          this._router.navigate(['/stores/create']);
        }
      }),
      filter(({ flow: { done } }) => done),
      map(({ store_id }) => this._store.dispatch(OnboardingActions.getSubscriptions({ storeId: store_id }))),
      switchMap(() => this.ofGetSubscriptionSuccessOrFailure$()),
      map(() => true)
    );
  }

  private ofGetSubscriptionSuccessOrFailure$ = () =>
    this._actions$.pipe(
      ofType(OnboardingActions.getSubscriptionsSuccess, OnboardingActions.getSubscriptionsFailure),
      first()
    );
}
