import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '@app/_shared/snack-bars/snack-bar-success.component';
import { SnackBarFailureComponent } from '@app/_shared/snack-bars/snack-bar-failure.component';
import { SnackBarConfig, SnackBarData } from '@app/_shared/snack-bars/snack-bar-data';
import { Errors } from '@app/_interface/error';
import { SnackBarErrorsComponent } from '@app/_shared/snack-bars/snack-bar-errors.component';

const DefaultConfig = {
  duration: 5,
} as SnackBarConfig;

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSucceeded(snackBarData: SnackBarData, config = DefaultConfig) {
    this.snackBar.openFromComponent(SnackBarSuccessComponent, {
      duration: config.duration * 1000,
      data: snackBarData,
      panelClass: ['mat-success'],
    });
  }

  openFailure(snackBarData: SnackBarData, config = DefaultConfig) {
    return this.snackBar.openFromComponent(SnackBarFailureComponent, {
      duration: config.duration * 1000,
      data: snackBarData,
      panelClass: ['mat-danger'],
    });
  }

  openNotice(snackBarData: SnackBarData, config = DefaultConfig) {
    this.snackBar.openFromComponent(SnackBarFailureComponent, {
      duration: config.duration * 1000,
      data: snackBarData,
    });
  }

  openErrorResponse(snackBarData: Errors, config = DefaultConfig) {
    this.snackBar.openFromComponent(SnackBarErrorsComponent, {
      duration: config.duration * 1000,
      data: snackBarData,
    });
  }
}
