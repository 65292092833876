import { Component, OnInit } from '@angular/core';
import {
  getActiveStores,
  getDefaultStore,
  hasFailedBookkeepingJob,
  isSaving,
} from '@app/main-store/bootstrap.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { DataObject, StoreBootstrap } from '@app/_shared/interfaces';
import { createSubscription } from '@app/main-store/subscriptions/subscription.actions';
import * as fromDashboardSelectors from './store/dashboard.selectors';
import { loadDashboardStatistics, loadInitialDashboardStatistics } from '@app/dashboard/store/dashboard.actions';

@Component({
  selector: 'sb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public defaultStore$: Observable<DataObject<StoreBootstrap>> = this.rxStore.select(getDefaultStore);
  public defaultStoreSubscription$ = this.defaultStore$.pipe(map((store) => store?.attributes.subscriptions));
  public savingSubscription$: Observable<boolean> = this.rxStore.select(isSaving);
  public activeStoresCount$: Observable<number> = this.rxStore
    .select(getActiveStores)
    .pipe(map((stores) => stores.length));
  public bookkeepingJobsCount$: Observable<number> = this.rxStore.select(
    fromDashboardSelectors.selectBookkeepingJobsCount
  );
  public ordersCount$: Observable<number> = this.rxStore.select(fromDashboardSelectors.selectOrdersCount);
  public integrationsCount$: Observable<number> = this.rxStore.select(fromDashboardSelectors.selectIntegrationsCount);
  public lastUpdatedAt$: Observable<string> = this.rxStore.select(fromDashboardSelectors.selectLastUpdatedAt).pipe(
    map((date) => {
      if (date === undefined) {
        return ' - ';
      }

      return date.toFormat('HH:mm');
    })
  );
  public loading$: Observable<boolean> = this.rxStore.select(fromDashboardSelectors.selectLoadingStatistics);
  public hasFailedBookkeepingJob$: Observable<boolean> = this.rxStore.select(hasFailedBookkeepingJob);

  constructor(private rxStore: Store<any>) {}

  ngOnInit() {
    this.rxStore.dispatch(loadInitialDashboardStatistics());
  }

  onReactivateSubscription(storeId: number) {
    this.rxStore.dispatch(
      createSubscription({
        data: { store_id: storeId },
      })
    );
  }

  onLoadStatistics() {
    this.rxStore.dispatch(loadDashboardStatistics());
  }
}
