import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataList } from '@app/_shared/interfaces';
import { PayoutProvider } from '@app/_shared/interfaces/http/payout-provider';

@Injectable({
  providedIn: 'root',
})
export class PayoutProvidersService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/payout-providers';

  constructor(private _http: HttpClient) {}

  index(params: HttpParams) {
    return this._http.get<DataList<PayoutProvider, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
