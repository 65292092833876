import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayText',
})
export class DisplayTextPipe implements PipeTransform {
  transform(item: any, itemDisplayTextProperties: string[], version = 'v2'): any {
    if (!item) {
      return '';
    }

    const values = [];

    for (const property of itemDisplayTextProperties) {
      let value = '';
      if (version === 'v2' && item['attributes']) {
        value = item['attributes'][property] ?? '';
      } else {
        value = item[property] ?? '';
      }

      if (value) {
        values.push(value);
      }
    }

    return values.join(' - ');
  }
}
