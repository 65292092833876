import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiIntegration, ApiIntegrationUpdate } from '@app/_shared/interfaces/http/api-integration';
import { DataList, ResponseObject } from '@app/_shared/interfaces/http/data';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ApiIntegrationsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/api-integrations';

  constructor(private _http: HttpClient) {}

  index(storeId: string) {
    const params = new HttpParams().set('filter[store_id]', storeId);

    return this._http.get<DataList<ApiIntegration, any>>(this.endpoint, {
      headers: this.headers,
      params: params,
    });
  }

  store(data: ApiIntegration) {
    return this._http.post<ResponseObject<ApiIntegration>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(id, data: ApiIntegrationUpdate) {
    return this._http.patch<ResponseObject<ApiIntegration>>(this.endpoint + '/' + id, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this._http.delete(this.endpoint + '/' + id, {
      headers: this.headers,
    });
  }
}
