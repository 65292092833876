import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataObject, Interval, SubscriptionPlans } from '@app/_shared/interfaces';

@Component({
  selector: 'sb-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent {
  @Input() subscriptionInterval: Interval = 'month';

  @Input() plans: DataObject<SubscriptionPlans>[] = [];
  @Input() selectedPlanId: number | null = null;
  @Input() currentPlanId: number | null = null;
  @Input() loading = false;

  @Output() selectPlan$ = new EventEmitter<number>();

  onSelectPlan(planId: number) {
    this.selectPlan$.emit(planId);
  }

  getFilteredPlans(interval): DataObject<SubscriptionPlans>[] {
    return this.plans.filter((plan) => plan.attributes.interval === interval);
  }
}
