import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  private baseUrl = environment.apiLaravelUrl + '/api/v1/shopify/app-install?';

  constructor() {}

  getShopifyInstallUrl(
    integrationId: string,
    storeId: string,
    domain: string,
    action: 'fresh-install' | 'app-fresh-install' | 'app-re-install'
  ) {
    const urlParams = new HttpParams()
      .append('action', action)
      .append('store_id', storeId)
      .append('api_integration_id', integrationId)
      .append('url', domain);

    return this.baseUrl + urlParams.toString();
  }
}
