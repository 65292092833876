import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrderBalanceReport, ResponseObject } from '@app/_shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrderBalanceReportsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/order-balance-reports';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<ResponseObject<OrderBalanceReport>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
