interface Base {
  id: string | number;
  attributes: any;
}

export function mergeAttributesById<T extends Base, Q extends Base>(
  array: T[],
  id: string | number,
  replaceObj: Q
): T[] {
  const idx = array.findIndex((x) => x.id === id);
  return [
    ...array.slice(0, idx),
    { ...array[idx], attributes: { ...array[idx].attributes, ...replaceObj.attributes } },
    ...array.slice(idx + 1),
  ];
}
